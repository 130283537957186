/* src/generic.css */
.hyphenate {
  hyphens: auto;
}
input,
select option {
  -prince-pdf-form: enable;
}

/* src/variables/headings.css */
h1 {
  string-set: onedocH1Contents content(text);
}
h2 {
  string-set: onedocH2Contents content(text) onedocH3Contents "" onedocH4Contents "" onedocH5Contents "" onedocH6Contents "";
}
h3 {
  string-set: onedocH3Contents content(text) onedocH4Contents "" onedocH5Contents "" onedocH6Contents "";
}
h4 {
  string-set: onedocH4Contents content(text) onedocH5Contents "" onedocH6Contents "";
}
h5 {
  string-set: onedocH5Contents content(text) onedocH6Contents "";
}
h6 {
  string-set: onedocH6Contents content(text);
}
.onedoc-heading-contents:not(:empty)::before {
  content: attr(data-before);
}
.onedoc-heading-contents:not(:empty)::after {
  content: attr(data-after);
}
.onedoc-h1-contents:before {
  content: string(onedocH1Contents);
}
.onedoc-h2-contents:before {
  content: string(onedocH2Contents);
}
.onedoc-h3-contents:before {
  content: string(onedocH3Contents);
}
.onedoc-h4-contents:before {
  content: string(onedocH4Contents);
}
.onedoc-h5-contents:before {
  content: string(onedocH5Contents);
}
.onedoc-h6-contents:before {
  content: string(onedocH6Contents);
}

/* src/variables/variables.css */

/* src/shell/shell.css */
@page {
  @top-left {
    content: flow(FileforgePageTop);
    content: element(FileforgePageTop);
    position: relative;
  }
  @bottom-left {
    content: flow(FileforgePageBottom);
    content: element(FileforgePageBottom);
    position: relative;
  }
}
.onedoc-page-top {
  position: running(FileforgePageTop) !important;
  -prince-flow: static(FileforgePageTop);
  width: 100%;
  height: 100%;
}
.onedoc-current-page-top {
  position: FileforgePageTop;
  width: 100%;
  height: 100%;
}
.onedoc-page-bottom {
  position: running(FileforgePageBottom) !important;
  -prince-flow: static(FileforgePageBottom);
  width: 100%;
  height: 100%;
}
.onedoc-page-break {
  page-break-after: always;
}
.onedoc-no-break {
  page-break-inside: avoid;
}

/* src/footnote/footnote.css */
.onedoc-footnote {
  float: footnote;
  footnote-style-position: inside;
}
/*# sourceMappingURL=index.css.map */