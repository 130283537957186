import React, { useState, useEffect } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import rfqColumns from './rfq-columns';
import rfqFilterValue from './rfq-sort';
import getRfqData from './rfq-data';
import updateTablePref from '../../database/set-table-pref';
import fetchTablePref from '../../database/fetch-table-pref';
const gridStyle = { height: '90vh' };

const RfqTable = ({ setOpenEditModal, setSelectedId }) => {
  const [rfqData, setRfqData] = useState([]);
  const [tablePrefrences, setTablePrefrences] = useState({});

  useEffect(() => {
    getRfqData((data) => {
      const filteredData = data.filter((item) => item.status !== 'no bid');
      console.log(filteredData, 'filteredData');
      setRfqData(filteredData);
    });

    fetchTablePref('RFQMaster')
      .then((tableData) => {
        if (tableData) setTablePrefrences(tableData);
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {
  //   const unsubscribe = getRfqData((data) => {
  //     const filteredData = data.filter((item) => item.status !== 'no bid');
  //     console.log(filteredData);
  //     setRfqData(filteredData);
  //   });

  //   fetchTablePref('RFQMaster')
  //     .then((tableData) => {
  //       if (tableData) setTablePrefrences(tableData);
  //     })
  //     .catch((err) => console.log(err));

  //   // Cleanup function to unsubscribe when the component unmounts
  //   return () => unsubscribe();
  // }, []);

  const handleColumnResize = async (e) => {
    const updatedColumnName = e.column.name;
    const updatedWidth = e.width;

    const preferences = {
      [updatedColumnName]: updatedWidth,
    };

    await updateTablePref('RFQMaster', preferences);
  };

  const handleColumnOrderChange = async (e) => {
    await updateTablePref('RFQMaster', { order: e });
  };

  return (
    <>
      <ReactDataGrid
        style={gridStyle}
        idProperty="uniqueId"
        defaultFilterValue={rfqFilterValue}
        columns={rfqColumns(
          setOpenEditModal,
          setSelectedId,
          setRfqData,
          tablePrefrences
        )}
        dataSource={rfqData}
        onColumnOrderChange={handleColumnOrderChange}
        pagination
        defaultLimit={20}
        rowHeight={60}
        // enableSelection
        onColumnResize={handleColumnResize}
        enableKeyboardNavigation
        showCellBorders={'horizontal'}
        columnMenu={{ lockable: false }}
      />
    </>
  );
};

export default RfqTable;
