import NavbarElement from '../components/sidebar/navbar';
import Sidebar from '../components/sidebar/sidebar';
import classNames from 'classnames';
import React, { useState } from 'react';
import RfqTable from '../components/rfqs/rfq-table';
import FloatingButton from '../components/floating-button';
import AddNewRfqComponent from '../components/rfqs/add-new-rfq';
import ViewRfqComponent from '../components/rfqs/rfqs-display-modal';
import { updateRFQ } from '../database/set-rfq-data';

function RFQsPage(props) {
  const [collapsed, setSidebarCollapsed] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleRFQUpdate = async () => {
    updateRFQ('d6c86583-c869-4f38-9455-f4fb456921f6', {
      rfqNo: '10758540',
    });
  };

  return (
    <div
      className={classNames({
        'grid bg-zinc-100 min-h-screen': true,
        'grid-cols-sidebar': !collapsed,
        'grid-cols-sidebar-collapsed': collapsed,
        'transition-[grid-template-columns] duration-300 ease-in-out': true,
      })}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <div>
          <Sidebar
            collapsed={collapsed}
            setCollapsed={setSidebarCollapsed}
            shown={showSidebar}
          />
        </div>
        <div className="container" style={{ overflow: 'auto' }}>
          <div className="h-screen flex flex-col">
            <NavbarElement
              onMenuButtonClick={() => setShowSidebar((prev) => !prev)}
            />
            {/* <button onClick={handleRFQUpdate}>Update RFQ</button> */}
            <div className="flex-grow overflow-hidden">
              <RfqTable
                setOpenEditModal={setOpenEditModal}
                openEditModal={openEditModal}
                setSelectedId={setSelectedId}
              />
            </div>
          </div>
          <FloatingButton
            onClick={() => setOpenModal(true)}
            text={'Add New RFQ'}
          />
          <AddNewRfqComponent
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
          <ViewRfqComponent
            openEditModal={openEditModal}
            setOpenEditModal={setOpenEditModal}
            docId={selectedId}
          />
        </div>
      </div>
    </div>
  );
}

export default RFQsPage;
