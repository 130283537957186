import { fetchAllRfqData } from '../../database/fetch-rfq-data';

const getRfqData = (callback) => {
  return fetchAllRfqData((rfqs) => {
    const rfqData = rfqs
      .filter((rfq) => rfq && rfq.status !== 'no bid') // Filter out null and "no bid" status RFQs
      .map((rfq) => {
        const originalDate = new Date(rfq.dueDate);
        const day = originalDate.getDate();
        const month = originalDate.getMonth() + 1;
        const year = originalDate.getFullYear();
        const formattedDate = `${month.toString().padStart(2, '0')}-${day
          .toString()
          .padStart(2, '0')}-${year}`;

        const originalReleaseDate = new Date(rfq.releaseDate);
        const releaseDay = originalReleaseDate.getDate();
        const releaseMonth = originalReleaseDate.getMonth() + 1;
        const releaseYear = originalReleaseDate.getFullYear();
        const formattedReleaseDate = `${releaseMonth
          .toString()
          .padStart(2, '0')}-${releaseDay
          .toString()
          .padStart(2, '0')}-${releaseYear}`;

        const releaseDate = new Date(rfq.releaseDate);
        const timestamp = releaseDate.getTime();
        return {
          uniqueId: rfq.rfqId,
          rfqNo: rfq.rfqNo.toString() || '',
          agency: rfq.agency || '',
          email: rfq.primaryEmail || '',
          emailSubject: rfq.emailSubject || '',
          dueBy: rfq.dueDate
            ? `${rfq.dueDate.split('-')[1]}-${rfq.dueDate.split('-')[2]}-${
                rfq.dueDate.split('-')[0]
              }`
            : '',
          lineItems: rfq.items?.length || 0,
          attachments: rfq.attachments
            ? Math.max(0, rfq.attachments.length - 1)
            : 0,
          quotes: rfq.quotes?.length || 0,
          assignedTo: rfq.assignedTo || '',
          status: rfq.status || '',
          releaseDate: rfq.releaseDate
            ? `${rfq.releaseDate.split('-')[1]}-${
                rfq.releaseDate.split('-')[2]
              }-${rfq.releaseDate.split('-')[0]}`
            : '',
          releaseDateObject: originalReleaseDate,
          realeseDateTimestamp: timestamp,
          timestamp: rfq.timestamp ? rfq.timestamp : timestamp,
        };
      })
      .sort((a, b) => b.timestamp - a.timestamp);

    rfqData.forEach((item, index) => {
      item.no = index + 1; // Adjust serial numbers based on the filtered and sorted list
      delete item.releaseDateObject;
    });
    callback(rfqData);
  });
};

export default getRfqData;
