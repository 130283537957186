import {
  getFirestore,
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
  deleteDoc,
  getDoc,
} from 'firebase/firestore';
import { app } from '../resources/gcp-config';
import { successAlert, errorAlert } from '../components/alerts';
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';

const db = getFirestore(app);
const storage = getStorage(app);

async function createAuth(formData) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_URL}/create-new-user`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          primaryEmail: formData.primaryEmail,
        }),
      }
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const userRecord = await response.json();
    return userRecord.uid; // Return the user uid
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
    throw error;
  }
}

/**
 * The function `createUser` creates a new user in a database using the provided form data and displays
 * a success alert.
 * @param formData - An object containing the data for the new user. It should have the following
 * properties:
 * @param userId - The `userId` parameter is the unique identifier for the user that you want to
 * create. It is used to specify the document path where the user data will be stored in the Firestore
 * database.
 */
const createUser = async (formData, userId) => {
  const { imageFile } = formData;
  delete formData.imageFile;
  if (imageFile) {
    const storageRef = ref(storage, `users_images/${imageFile.name}`);
    try {
      // Upload the file
      const snapshot = await uploadBytes(storageRef, imageFile);
      console.log('Uploaded a blob or file!', snapshot);

      // Get the download URL
      formData.photoURL = await getDownloadURL(snapshot.ref);
      console.log('File available at', formData.photoURL);
    } catch (error) {
      console.error('Upload failed:', error);
    }
  }
  await setDoc(doc(db, 'users', userId), {
    name: formData.name,
    username: formData.username,
    role: formData.role,
    primaryPhone: formData.primaryPhone,
    secondaryPhone: formData.secondaryPhone,
    primaryEmail: formData.primaryEmail,
    secondaryEmail: formData.secondaryEmail,
    timestamp: serverTimestamp(),
    photoURL: formData.photoURL ?? '',
  });

  successAlert('Success!', 'A new user has been added.');
};

/**
 * The function handles the submission of a user form by creating authentication and user data, and
 * displays an error alert if there is an error.
 * @param formData - The `formData` parameter is an object that contains the data submitted by the user
 * in a form. It typically includes fields such as username, email, password, etc.
 */
async function handleUserFormSubmit(formData) {
  try {
    const userId = await createAuth(formData);
    await createUser(formData, userId);
  } catch (error) {
    errorAlert('Error!', 'There was an error creating the user.');
  }
}

const updateUser = async (userId, formData) => {
  try {
    // Reference to the Firestore document
    const userDocRef = doc(db, 'users', userId);
    const { imageFile } = formData;
    delete formData.imageFile; // Remove imageFile from formData as it's not needed in Firestore

    if (imageFile) {
      // Get the current user data to find the old image URL
      const userDoc = await getDoc(userDocRef);
      const currentUserData = userDoc.data();

      if (currentUserData && currentUserData.photoURL) {
        try {
          // Extract the correct path for the old image
          const photoPath = currentUserData.photoURL
            .replace(
              `https://firebasestorage.googleapis.com/v0/b/${storage.app.options.storageBucket}/o/`,
              ''
            )
            .split('?')[0];
          const oldImageRef = ref(storage, decodeURIComponent(photoPath));

          // Delete the old image
          await deleteObject(oldImageRef);
          console.log('Deleted old image:', currentUserData.photoURL);
        } catch (error) {
          if (error.code === 'storage/object-not-found') {
            console.log('Old image not found, skipping delete.');
          } else {
            throw error;
          }
        }
      }

      // Create a reference to the new image storage location
      const storageRef = ref(
        storage,
        `users_images/${Date.now()}-${imageFile.name}`
      );

      // Upload the new image
      const snapshot = await uploadBytes(storageRef, imageFile);

      // Get the download URL for the new image
      formData.photoURL = await getDownloadURL(snapshot.ref);
      console.log('Uploaded new image, URL:', formData.photoURL);
    }

    // Update the user document with the new data and image URL if available
    await updateDoc(userDocRef, {
      ...formData,
      updatedAt: serverTimestamp(),
    });

    // Show success alert
    successAlert('Success!', 'The User has been updated.');
  } catch (error) {
    console.error('Error updating user:', error);
    // Show error alert
    errorAlert('Error!', 'An error occurred while updating the user.');
  }
};

const deleteUser = async (userId) => {
  try {
    await deleteDoc(doc(db, 'users', userId));
    successAlert('Success!', 'The user has been deleted.');
  } catch (error) {
    errorAlert('Error!', 'There was an error deleting the user.');
    throw error;
  }
};

export { handleUserFormSubmit, updateUser, deleteUser };
