import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import '@inovua/reactdatagrid-community/index.css';
import 'react-multi-email/dist/style.css';
import Loader from '../loader/loader.js';
import AddNewDeliveryComponent from '../deliveries/add-new-delivery.js';
import AddNewPosComponent from '../pos/add-new-po.js';
import {
  fetchSingleEmailData,
  updateEmailClassify,
} from '../../database/fetch-email-data';
import PreviewFileModal from '../preview-file-modal.js';
import ProcessRFQRecord from '../rfqs/process-rfq.js';

function EmailModal({ openEditModal, setOpenEditModal, docId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [headerStatement, setHeaderStatement] = useState('');
  const [isProcessNowClicked, setIsProcessNowClicked] = useState(false);
  const [openAddNewDeliveryModal, setOpenAddNewDeliveryModal] = useState(false);
  const [openAddNewRfqModal, setOpenAddNewRfqModal] = useState(false);
  const [openAddNewPoModal, setopenAddNewPoModal] = useState(false);
  const [openFilePreviewModal, setOpenFilePreviewModal] = useState(false);
  const [previewFileUrl, setPreviewFileUrl] = useState('');

  useEffect(() => {
    if (!docId) return;

    setIsLoading(true);
    const unsubscribe = fetchSingleEmailData(docId, (emailData) => {
      if (!emailData) return;

      setHeaderStatement(
        `${emailData?.subject} from ${emailData?.sender?.name}`
      );
      setData({
        from: emailData?.sender?.email,
        subject: emailData?.subject,
        datetime: new Date(emailData?.timestamp.seconds * 1000).toString(),
        content: emailData?.body,
        attachments: emailData?.attachments,
      });
      setIsLoading(false);
    });

    return () => unsubscribe && unsubscribe();
  }, [docId]);

  if (isLoading) {
    return <Loader />;
  }

  const handleFilePreviewClick = (url) => {
    setPreviewFileUrl(url);
    setOpenFilePreviewModal(true);
  };

  const handleProcessRfq = () => {
    updateEmailClassify(docId, 'RFQ');
    setOpenAddNewRfqModal(false);
  };

  return (
    <>
      <ModalContainer
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        title={headerStatement}
      >
        <div className="space-y-4 max-h-[calc(100vh-15rem)] overflow-y-auto">
          <div className="grid gap-4 grid-cols-1 sm:gap-6 mb-5">
            {isProcessNowClicked ? (
              <ProcessButtons
                setOpenEditModal={setOpenEditModal}
                setOpenAddNewRfqModal={setOpenAddNewRfqModal}
                setopenAddNewPoModal={setopenAddNewPoModal}
                setOpenAddNewDeliveryModal={setOpenAddNewDeliveryModal}
              />
            ) : (
              <EmailDetails
                data={data}
                handleFilePreviewClick={handleFilePreviewClick}
              />
            )}
          </div>
        </div>
        <ModalFooter>
          {isProcessNowClicked ? (
            <CancelButton onClick={() => setIsProcessNowClicked(false)} />
          ) : (
            <ProcessNowButton onClick={() => setIsProcessNowClicked(true)} />
          )}
        </ModalFooter>
      </ModalContainer>

      <AddNewDeliveryComponent
        openModal={openAddNewDeliveryModal}
        setOpenModal={setOpenAddNewDeliveryModal}
      />
      <AddNewPosComponent
        openModal={openAddNewPoModal}
        setOpenModal={setopenAddNewPoModal}
      />
      <ProcessRFQRecord
        openModal={openAddNewRfqModal}
        setOpenModal={setOpenAddNewRfqModal}
        handleProcessEvent={handleProcessRfq}
      />
      <PreviewFileModal
        openModal={openFilePreviewModal}
        setOpenModal={setOpenFilePreviewModal}
        fileLink={previewFileUrl}
        attachments={data.attachments}
      />
    </>
  );
}

// Reusable Modal Container component for flexibility and responsiveness
const ModalContainer = ({ open, onClose, title, children }) => (
  <div
    id="addUserData-modal"
    tabIndex="-1"
    aria-hidden="true"
    className={`${
      open
        ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed inset-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
        : 'hidden'
    }`}
  >
    <div className="relative w-full max-w-4xl p-4">
      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            {title}
          </h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={onClose}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className="p-4 md:p-5">{children}</div>
      </div>
    </div>
  </div>
);

const ModalFooter = ({ children }) => (
  <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
    {children}
  </div>
);

// Process buttons extracted to reusable component
const ProcessButtons = ({
  setOpenEditModal,
  setOpenAddNewRfqModal,
  setopenAddNewPoModal,
  setOpenAddNewDeliveryModal,
}) => {
  const buttons = [
    { label: 'Process RFQ', action: () => setOpenAddNewRfqModal(true) },
    { label: 'Process PO', action: () => setopenAddNewPoModal(true) },
    { label: 'Process VQ', action: () => {} },
    { label: 'Process PO Confirmation', action: () => {} },
    { label: 'Process DR', action: () => setOpenAddNewDeliveryModal(true) },
  ];

  return (
    <div className="flex flex-wrap justify-center items-center w-full h-full space-x-2 overflow-auto">
      {buttons.map((button, index) => (
        <ProcessButton
          key={index}
          label={button.label}
          onClick={() => {
            setOpenEditModal(false);
            button.action();
          }}
        />
      ))}
    </div>
  );
};

// Generic button component for process actions
const ProcessButton = ({ label, onClick }) => (
  <button
    type="button"
    className="text-white my-2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
    onClick={onClick}
  >
    {label}
  </button>
);

const EmailDetails = ({ data, handleFilePreviewClick }) => {
  const details = [
    { id: 'from', label: 'From', value: data.from },
    { id: 'subject', label: 'Subject', value: data.subject },
    { id: 'datetime', label: 'Date/Time', value: data.datetime },
  ];

  return (
    <>
      {details.map((detail) => (
        <DetailField
          key={detail.id}
          id={detail.id}
          label={detail.label}
          value={detail.value}
        />
      ))}
      <div className="sm:col-span-2">
        <label
          htmlFor="content"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Content
        </label>
        <div
          id="content"
          className="block p-2.5 w-full text-sm"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      </div>
      <div className="sm:col-span-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Attachments
        </label>
        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
          {data.attachments?.map((attachment, index) => (
            <li
              key={index}
              className="py-3 flex items-center justify-between text-sm"
            >
              <span className="text-gray-900 dark:text-white">
                {attachment.name}
              </span>
              <button
                type="button"
                className="text-blue-600 hover:text-blue-900 dark:text-blue-500 dark:hover:text-blue-400"
                onClick={() => handleFilePreviewClick(attachment.attachmentUrl)}
              >
                Preview
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

const DetailField = ({ id, label, value }) => (
  <div className="sm:col-span-2">
    <label
      htmlFor={id}
      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
    >
      {label}
    </label>
    <input
      type="text"
      id={id}
      className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
      placeholder={label.toLowerCase()}
      defaultValue={value}
      required
    />
  </div>
);

const CancelButton = ({ onClick }) => (
  <button
    type="button"
    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 border border-gray-200 rounded-lg text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
    onClick={onClick}
  >
    Cancel
  </button>
);

const ProcessNowButton = ({ onClick }) => (
  <button
    type="button"
    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
    onClick={onClick}
  >
    Process Now
  </button>
);

export default EmailModal;
