import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyAvDYCpxXFBRYf-CQz5qj1XGmEZwMwBRKI",
  authDomain: "yaapp-oct24-demo.firebaseapp.com",
  projectId: "yaapp-oct24-demo",
  storageBucket: "yaapp-oct24-demo.appspot.com",
  messagingSenderId: "371492433575",
  appId: "1:371492433575:web:71afb0184e932b694cb582",
  measurementId: "G-VXTBQSNNZH"
};

export const app = initializeApp(firebaseConfig);
