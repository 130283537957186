import { useRef, useState } from 'react';
import { handleUserFormSubmit } from '../../database/set-user-data';
import Loader from '../loader/loader';
import { warnAlert } from '../alerts';
import { UserCircleIcon } from '@heroicons/react/24/outline';

function AddUserComponent({ openModal, setOpenModal }) {
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('User');
  const [primaryPhone, setPrimaryPhone] = useState('');
  const [secondaryPhone, setSecondaryPhone] = useState('');
  const [primaryEmail, setPrimaryEmail] = useState('');
  const [secondaryEmail, setSecondaryEmail] = useState('');
  const [isPrimaryEmailValid, setIsPrimaryEmailValid] = useState(true);
  const [isSecondaryEmailValid, setIsSecondaryEmailValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false); // New state variable for loading status
  const [userImagePreview, setUserImagePreview] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const fileRef = useRef(null);
  /**
   * The `handlePhoneNumberChange` function formats a phone number input by adding parentheses, spaces,
   * and dashes in the appropriate places.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. In this case, it is likely an event object related to a change event on an input field.
   */
  const handlePhoneNumberChange = (event, setPhone) => {
    let input = event.target.value.replace(/\D/g, '');

    if (input.length > 10) {
      input = input.substring(0, 10);
    }

    let size = input.length;

    if (size < 4) {
      input = size !== 0 ? '(' + input : input;
    } else if (size < 7) {
      input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6);
    } else {
      input =
        '(' +
        input.substring(0, 3) +
        ') ' +
        input.substring(3, 6) +
        '-' +
        input.substring(6, 10);
    }

    event.target.value = input;
    setPhone(input);
  };

  /**
   * The below code defines two functions, handlePrimaryEmailChange and handleSecondaryEmailChange, which
   * handle changes to the primary and secondary email inputs respectively and validate the email format
   * using a regular expression.
   * @param event - The `event` parameter is an object that represents the event that occurred. In this
   * case, it is an event object related to the email input field. It contains information about the
   * event, such as the target element (the input field), the value of the input field, and other
   * properties and methods
   */
  const handlePrimaryEmailChange = (event) => {
    setPrimaryEmail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsPrimaryEmailValid(emailRegex.test(event.target.value));
  };

  const handleSecondaryEmailChange = (event) => {
    setSecondaryEmail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsSecondaryEmailValid(emailRegex.test(event.target.value));
  };

  /**
   * The handleNameChange function generates a username by replacing spaces with underscores and
   * converting the string to lowercase.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. In this case, it is an event object that is generated when the value of an input field is
   * changed.
   */
  const handleNameChange = (event) => {
    const generatedUsername = event.target.value
      .replace(/\s+/g, '_')
      .toLowerCase();
    setUsername(generatedUsername);
    setName(event.target.value);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    const acceptedFileTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/webp',
    ];
    const maxFileSize = 5 * 1024 * 1024;

    if (file) {
      if (!acceptedFileTypes.includes(file.type)) {
        warnAlert('Please upload a valid image file (JPEG, PNG, GIF, WEBP).');
        fileRef.current.value = '';
        return;
      }
      if (file.size > maxFileSize) {
        warnAlert('File size exceeds the 5 MB limit.');
        fileRef.current.value = '';
        return;
      }
      setImageFile(file);
      setUserImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      warnAlert('Please fill in all required fields.');
      return;
    }
    setOpenModal(false);
    setIsLoading(true); // Set loading to true when button is clicked
    const formData = {
      name: name,
      username: username,
      role: role,
      primaryPhone: primaryPhone,
      secondaryPhone: secondaryPhone,
      primaryEmail: primaryEmail,
      secondaryEmail: secondaryEmail,
      imageFile: imageFile,
    };
    await handleUserFormSubmit(formData);
    clearFormStates();
    window.location.reload();
  };

  const validateForm = () => {
    const errors = {};
    if (!name.trim()) errors.name = true;
    if (!username.trim()) errors.username = true;
    if (!role) errors.role = true;
    if (!primaryEmail || !isPrimaryEmailValid) errors.primaryEmail = true;
    if (!primaryPhone) errors.primaryPhone = true;
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const clearFormStates = () => {
    setIsLoading(false);
    setName('');
    setUsername('');
    setRole('User');
    setPrimaryPhone('');
    setSecondaryPhone('');
    setPrimaryEmail('');
    setSecondaryEmail('');
    setIsPrimaryEmailValid(true);
    setIsSecondaryEmailValid(true);
    setUserImagePreview('');
    setImageFile(null);
    setValidationErrors({});
  };

  const closeModal = () => {
    setOpenModal(false);
    clearFormStates();
  };

  return (
    <>
      {isLoading && <Loader />}

      <div
        id="addUserData-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={
          openModal
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-2xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Add New User
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={closeModal}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4">
              <section className="bg-white dark:bg-gray-900">
                <div className="mx-auto max-w-2xl">
                  <form>
                    <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="image"
                          className="block mt-2 mx-auto cursor-pointer w-32 h-32  items-center justify-center border rounded-full border-gray-300 relative"
                        >
                          <input
                            id="image"
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="absolute top-0 left-0 opacity-0 cursor-pointer w-32 h-32"
                            ref={fileRef}
                          />
                          {userImagePreview ? (
                            <img
                              src={userImagePreview}
                              alt="Image Preview"
                              className="w-full h-full object-cover rounded-full"
                            />
                          ) : (
                            <div className="w-full h-full flex items-center justify-center bg-gray-200 rounded-full">
                              <UserCircleIcon className="w-32 h-32" />
                            </div>
                          )}
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Full Name <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={name}
                          onChange={handleNameChange}
                          className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500
                            ${
                              validationErrors.name
                                ? 'border-red-600 dark:border-red-600'
                                : 'border-gray-300 dark:border-gray-600'
                            }`}
                          placeholder="John Doe"
                          required
                        />
                        {validationErrors.name && (
                          <p className="text-red-600 text-sm">
                            Name is required.
                          </p>
                        )}
                      </div>
                      <div>
                        <label
                          htmlFor="username"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Username <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="username"
                          id="username"
                          className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500
                            ${
                              validationErrors.username
                                ? 'border-red-600 dark:border-red-600'
                                : 'border-gray-300 dark:border-gray-600'
                            }`}
                          placeholder="john_doe"
                          value={username}
                          required
                        />
                        {validationErrors.username && (
                          <p className="text-red-600 text-sm">
                            Username is required.
                          </p>
                        )}
                      </div>
                      <div>
                        <label
                          htmlFor="role"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Role <span className="text-red-600">*</span>
                        </label>
                        <select
                          id="role"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          required
                          onChange={(event) => setRole(event.target.value)}
                          value="User"
                        >
                          <option value="User">User</option>
                          <option value="Management">Management</option>
                          <option value="IT_support">IT Support</option>
                          <option value="Admin">Admin</option>
                        </select>
                        {validationErrors.role && (
                          <p className="text-red-600 text-sm">
                            Role is required.
                          </p>
                        )}
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="primary_phone"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Primary Phone <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="primary_phone"
                          id="primary_phone"
                          value={primaryPhone}
                          onChange={(event) =>
                            handlePhoneNumberChange(event, setPrimaryPhone)
                          }
                          className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500
                             ${
                               validationErrors.primaryPhone
                                 ? 'border-red-600 dark:border-red-600'
                                 : 'border-gray-300 dark:border-gray-600'
                             }
                            `}
                          placeholder="(123) 456-7890"
                          required
                        />
                        {validationErrors.primaryPhone && (
                          <p className="text-red-600 text-sm">
                            Primary Phone is required.
                          </p>
                        )}
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="secondary_phone"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Secondary Phone
                        </label>
                        <input
                          type="text"
                          name="secondary_phone"
                          id="secondary_phone"
                          value={secondaryPhone}
                          onChange={(event) =>
                            handlePhoneNumberChange(event, setSecondaryPhone)
                          }
                          className="bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="(123) 456-7890"
                          required
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="primary_email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Primary Email <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="primary_email"
                          id="primary_email"
                          onChange={handlePrimaryEmailChange}
                          value={primaryEmail}
                          className={`bg-gray-50 border ${
                            isPrimaryEmailValid
                              ? 'border-gray-300'
                              : 'border-red-600'
                          } text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                          placeholder="user@domain.com"
                          required
                        />
                        {validationErrors.primaryEmail && (
                          <p className="text-red-600 text-sm">
                            Primary Email is required.
                          </p>
                        )}
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="secondary_email"
                          className="block text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Secondary Email
                        </label>
                        <input
                          type="text"
                          name="secondary_email"
                          id="secondary_email"
                          onChange={handleSecondaryEmailChange}
                          value={secondaryEmail}
                          className={`bg-gray-50 border ${
                            isSecondaryEmailValid
                              ? 'border-gray-300'
                              : 'border-red-600'
                          } text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                          placeholder="user@domain.com"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </section>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                onClick={handleSubmit}
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Add User
              </button>
              <button
                onClick={closeModal}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddUserComponent;
