// Function to sort the data array by `updatedAt`
const sortByUpdatedAt = (data, order = 'asc') => {
  return data.sort((a, b) => {
    const dateA = new Date(a.updatedAt);
    const dateB = new Date(b.updatedAt);

    if (order === 'asc') {
      return dateA - dateB; // Ascending order
    } else {
      return dateB - dateA; // Descending order
    }
  });
};

const deliveryFilterValue = [
  { name: 'deliveryDate', operator: 'contains', type: 'string', value: '' },
  { name: 'spoId', operator: 'contains', type: 'string', value: '' },
  { name: 'vpoId', operator: 'contains', type: 'string', value: '' },
  { name: 'vendorName', operator: 'contains', type: 'string', value: '' },
  { name: 'vendorEmail', operator: 'contains', type: 'string', value: '' },
  { name: 'delQty', operator: 'gte', type: 'number', value: '' },
  { name: 'saiQty', operator: 'gte', type: 'number', value: '' },
  { name: 'trackingID', operator: 'contains', type: 'string', value: '' },
  { name: 'trackingPartner', operator: 'contains', type: 'string', value: '' },
  { name: 'status', operator: 'contains', type: 'string', value: '' },
  {
    name: 'updatedAt',
    operator: 'contains',
    type: 'string',
    value: '',
    sort: sortByUpdatedAt,
  },
];
export default deliveryFilterValue;
