import NavbarElement from '../components/sidebar/navbar';
import Sidebar from '../components/sidebar/sidebar';
import classNames from 'classnames';
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import Cropper from 'react-easy-crop';

import 'preline/preline';
import { fetchUserData } from '../database/fetch-user-data';
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  getAuth,
  linkWithPopup,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  unlink,
  updatePassword,
} from 'firebase/auth';
import { errorAlert, successAlert } from '../components/alerts';
import { updateUser } from '../database/set-user-data';
import { app } from '../resources/gcp-config';
import '../components/styles/dashboard.css';

const auth = getAuth(app);

const openCropModal = () => {
  const button = document.querySelector(
    '[data-hs-overlay="#hs-pro-deum-crop"]'
  );
  if (button) {
    const event = new Event('click', { bubbles: true });
    button.dispatchEvent(event);
  } else {
    console.warn(
      'Button for opening modal not found, attempting direct toggle...'
    );
    const modal = document.querySelector('#hs-pro-deum-crop');
    if (modal) {
      modal.classList.remove('hidden', 'pointer-events-none', '');
      modal.classList.add(
        'hs-overlay-open',
        'pointer-events-auto',
        'opacity-100'
      );
    } else {
      console.error('Modal element not found.');
    }
  }
};

const ProfilePage = () => {
  const location = useLocation();
  const [collapsed, setSidebarCollapsed] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isGoogleConnected, setIsGoogleConnected] = useState(false);

  const [croppedImage, setCroppedImage] = useState(null);
  const [isCropLoading, setIsCropLoading] = useState(false);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  const getUserData = useCallback(async () => {
    fetchUserData().then((user) => {
      setName(user.name);
      const isGoogleProvider = auth.currentUser?.providerData.some(
        (provider) => provider.providerId === 'google.com'
      );

      setIsGoogleConnected(isGoogleProvider ?? false);
      // console.log(user);
      setUserData(user);
    });
  }, []);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  async function reauthenticateWithPassword(currentUser, password) {
    const email = currentUser.email;

    if (email) {
      // Check if email is not null
      const credential = EmailAuthProvider.credential(email, password);
      try {
        await reauthenticateWithCredential(currentUser, credential);
      } catch (error) {
        if (error.code === 'auth/wrong-password') {
          throw new Error('Incorrect password');
        } else {
          throw error;
        }
      }
    } else {
      throw new Error('Current user email is null');
    }
  }

  const handlePasswordChange = () => {
    if (newPassword !== confirmPassword) {
      errorAlert('Error!', 'Passwords do not match.');
      return;
    }
    if (newPassword.length < 8) {
      errorAlert('Error!', 'Password must be at least 8 characters long.');
      return;
    }
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*-]).{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      errorAlert(
        'Error!',
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
      );
      return;
    }

    setIsLoading(true);
    const auth = getAuth(app);
    const user = auth.currentUser;
    if (user) {
      reauthenticateWithPassword(user, currentPassword)
        .then(() => {
          // console.log('Authenticated');
          updatePassword(user, newPassword)
            .then(async () => {
              // console.log('Update Successful');
              setIsLoading(false);
              setNewPassword('');
              setCurrentPassword('');
              setConfirmPassword('');
              successAlert('Success!', 'Password changed successfully.');
            })
            .catch((error) => {
              // console.log('An error Occured');
              setIsLoading(false);
              errorAlert('Error!', 'Unable to change password.');
            });
        })
        .catch((error) => {
          // console.log('Error during reauthentication');
          setIsLoading(false);
          errorAlert('Error!', 'Unable to change password.');
        });
    }
  };

  const handlePersonalInfoChange = () => {
    if (name.length < 1) {
      errorAlert('Error!', 'Name must be at least 1 character long.');
      return;
    }
    setIsLoading(true);
    updateUser(userData.docId, { name }).catch((error) => {
      errorAlert('Error!', 'Unable to change name.');
      setIsLoading(false);
    });
    setIsLoading(false);
  };

  const handleForgetPassword = async () => {
    setIsLoading(true);
    const user = auth.currentUser;
    // console.log(user.email);
    if (user?.email === '') {
      setIsLoading(false);
      errorAlert('Error!', 'Please enter your email address.');
    } else {
      try {
        if (user?.email) {
          sendPasswordResetEmail(auth, user.email)
            .then(() => {
              setIsLoading(false);
              successAlert('Success!', 'Password reset email sent.');
            })
            .catch((error) => {
              // console.log('An error Occured', error);
              setIsLoading(false);
              errorAlert('Error!', 'Could not send the password reset email.');
            });
        } else {
          console.error('Email is undefined.');
        }
      } catch (error) {
        setIsLoading(false);
        console.error(error);
        const errorMessage = error
          ? `Unexpected error occurred: ${error.message}`
          : 'An unexpected error occurred.';
        errorAlert('Error!', errorMessage);
      }
    }
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files ? event.target.files[0] : null;
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       // Check if the result is a string before setting the state
  //       const result = e.target?.result;
  //       if (typeof result === 'string') {
  //         setImageSrc(result);
  //         openCropModal(); // Ensure this modal now contains the Cropper and uses imageSrc
  //       } else {
  //         console.error('File content could not be read as a base64 string.');
  //         setImageSrc(null); // Set to null or handle appropriately if the result isn't a string
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //     return;
  //   }
  //   // Handle the case where no file is selected
  //   setImageSrc(null);
  // };

  const handleFileChange = (event) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result;
        if (typeof result === 'string') {
          setImageSrc(result);
          console.log('File successfully read, opening modal...');
          openCropModal();
        } else {
          console.error('File content could not be read as a base64 string.');
          setImageSrc(null);
        }
      };
      reader.readAsDataURL(file);
    } else {
      console.error('No file selected.');
      setImageSrc(null);
    }
  };

  async function updatePhotoUrl(userId, file, progressCallback) {
    try {
      const storage = getStorage(app);
      const storageRef = ref(storage, `user-profiles/${userId}.jpg`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          progressCallback(progress); // Call the progress callback with the current progress
        },
        (error) => {
          throw error; // Rethrow the error to be caught by the try-catch block
        }
      );

      // Wait for upload to complete
      await uploadTask;

      return await getDownloadURL(uploadTask.snapshot.ref); // Return the download URL
    } catch (error) {
      console.error('Error updating user profile:', error);
      throw error; // Rethrow the error to be handled by the caller
    }
  }

  const createCroppedImage = async (imageSrc, pixelCrop) => {
    const image = new Image();
    image.src = imageSrc;
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('Failed to get canvas context');
    }

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    // Convert canvas to Blob
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    if (!imageSrc) return; // Ensure there's an image to crop

    const croppedImageBlob = await createCroppedImage(
      imageSrc,
      croppedAreaPixels
    );
    if (croppedImageBlob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Ensure reader.result is treated as a string
        setCroppedImage(reader.result);
      };
      reader.readAsDataURL(croppedImageBlob); // Assert that croppedImageBlob is a Blob
      return;
    }
    console.error('Failed to create cropped image');
    setCroppedImage(null);
  };

  const submitCroppedImage = async () => {
    setIsCropLoading(true); // Assuming you have a loading state to indicate progress
    try {
      // Ensure croppedImage is not null or undefined
      if (!croppedImage) {
        console.error('No cropped image to submit.');
        return;
      }

      const userId = userData.docId;

      // // console.log(croppedImage);

      const imageBlob = await (await fetch(croppedImage)).blob();

      // Assuming croppedImage is a Blob. If it's a base64 string, you'll need to convert it to a Blob first.
      const file = new File([imageBlob], 'profile-pic.jpg', {
        type: 'image/jpeg',
      });

      const progressCallback = (progress) => {
        // console.log(`Upload is ${progress}% done`);
      };

      const photoUrl = await updatePhotoUrl(userId, file, progressCallback);
      // console.log('Photo URL:', photoUrl);

      if (userId && photoUrl) {
        const updatedUserDetails = {
          photoURL: photoUrl,
        };
        setUserData((prev) => ({ ...prev, photoURL: photoUrl }));
        await updateUser(userId, updatedUserDetails);
        // console.log('User profile updated successfully.');
      } else {
        console.error('User or organization ID is missing.');
      }
    } catch (error) {
      console.error('Error submitting cropped image:', error);
    } finally {
      setIsCropLoading(false);
      openCropModal();
    }
  };

  const handleProfilePhotoDelete = () => {
    setIsLoading(true);
    updateUser(userData.docId, { photoURL: '' }).catch((error) => {
      errorAlert('Error!', 'Unable to delete profile photo.');
      setIsLoading(false);
    });
    setUserData((prev) => ({ ...prev, photoURL: '' }));
    setIsLoading(false);
  };

  const handleGoogleConnect = () => {
    const googleProvider = new GoogleAuthProvider();
    const user = auth.currentUser;

    if (user) {
      linkWithPopup(user, googleProvider)
        .then(async (result) => {
          // console.log('Google Account successfully linked');
          setIsGoogleConnected(true);
        })
        .catch((error) => {
          if (error.code === 'auth/credential-already-in-use') {
            // console.log('Credential already in use.');
            const providerId = googleProvider.providerId;
            // console.log(providerId);
            // console.log(user);
            if (user) {
              unlink(user, providerId)
                .then(() => {
                  // console.log('Provider unlinked from account');
                  setIsGoogleConnected(false);
                })
                .catch((error) => {
                  console.error('Error unlinking provider:', error);
                });
            } else {
              console.error('No user is currently signed in.');
            }
          } else {
            console.error('Other error:', error);
          }
        });
    } else {
      // console.log('User is null');
    }
  };

  return (
    <>
      <div
        className={classNames({
          'grid bg-zinc-100 min-h-screen': true,
          'grid-cols-sidebar': !collapsed,
          'grid-cols-sidebar-collapsed': collapsed,
          'transition-[grid-template-columns] duration-300 ease-in-out': true,
        })}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <div>
            <Sidebar
              collapsed={collapsed}
              setCollapsed={setSidebarCollapsed}
              shown={showSidebar}
              isCropLoading={isCropLoading}
            />
          </div>

          <div
            className="container"
            style={{ overflow: 'auto', height: '100vh' }}
          >
            <div className="">
              <NavbarElement
                onMenuButtonClick={() => setShowSidebar((prev) => !prev)}
              />

              <div className="p-5 md:p-8 bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                <div className="mb-4 xl:mb-8">
                  <h1 className="text-lg font-semibold text-gray-800 dark:text-neutral-200">
                    Profile
                  </h1>
                  <p className="text-sm text-gray-500 dark:text-neutral-500">
                    Manage your name, password and account settings.
                  </p>
                </div>

                <form>
                  <div className="py-6 sm:py-8 space-y-5 border-t border-gray-200 first:border-t-0 dark:border-neutral-700">
                    <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                      <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2">
                        <label className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
                          Avatar
                        </label>
                      </div>

                      <div className="sm:col-span-8 xl:col-span-4">
                        <div className="flex flex-wrap items-center gap-3 sm:gap-5">
                          <label
                            htmlFor="hs-pro-dappiph-upload-image"
                            className="flex justify-center items-center h-20 w-20 border-2 border-dotted border-gray-300 text-gray-400 rounded-full dark:border-neutral-700 dark:text-neutral-600"
                          >
                            {userData?.photoURL ? (
                              <img
                                src={userData.photoURL}
                                alt="Profile"
                                className="h-20 w-20 object-cover rounded-full" // Adjust the size as needed
                              />
                            ) : (
                              <svg
                                className="flex-shrink-0 w-7 h-7"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="1"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <rect
                                  width="18"
                                  height="18"
                                  x="3"
                                  y="3"
                                  rx="2"
                                  ry="2"
                                />
                                <circle cx="9" cy="9" r="2" />
                                <path d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21" />
                              </svg>
                            )}
                          </label>

                          <div className="grow">
                            <div className="flex items-center gap-x-2">
                              <label className="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-500">
                                <svg
                                  className="flex-shrink-0 size-4"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                  <polyline points="17 8 12 3 7 8" />
                                  <line x1="12" x2="12" y1="3" y2="15" />
                                </svg>
                                {userData?.photoURL
                                  ? 'Change photo'
                                  : 'Upload photo'}
                                <input
                                  id="hs-pro-dappiph-upload-image"
                                  type="file"
                                  accept="image/*"
                                  className="hidden"
                                  onChange={handleFileChange}
                                />
                              </label>
                              <button
                                type="button"
                                className="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-gray-200 bg-white text-red-500 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-red-500 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                onClick={handleProfilePhotoDelete}
                              >
                                Delete
                              </button>
                            </div>
                            <p className="mt-2 text-xs text-gray-500 dark:text-neutral-500">
                              Pick a photo up to 1MB.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="py-6 sm:py-8 space-y-5 border-t border-gray-200 first:border-t-0 dark:border-neutral-700">
                    <h2 className="font-semibold text-gray-800 dark:text-neutral-200">
                      Personal info
                    </h2>

                    <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                      <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2">
                        <label
                          htmlFor="hs-pro-dappinm"
                          className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"
                        >
                          Name
                        </label>
                      </div>

                      <div className="sm:col-span-8 xl:col-span-4">
                        <input
                          id="hs-pro-dappinm"
                          type="text"
                          className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                          placeholder="Enter full name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />

                        <p className="mt-2 text-sm text-gray-500 dark:text-neutral-500">
                          Enter your full name, or a display name you are
                          comfortable with.
                        </p>
                      </div>
                    </div>

                    <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                      <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2">
                        <label
                          htmlFor="hs-pro-dappiem"
                          className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"
                        >
                          Email
                        </label>
                      </div>

                      <div className="sm:col-span-8 xl:col-span-4">
                        <input
                          id="hs-pro-dappiem"
                          type="text"
                          className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                          placeholder="Enter email address"
                          defaultValue={userData.primaryEmail}
                          disabled
                        />

                        <p className="mt-2 text-sm text-gray-500 dark:text-neutral-500">
                          Enter the email address you want to use to log in with
                          YAAPP.
                        </p>
                      </div>
                    </div>

                    <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                      <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2"></div>

                      <div className="sm:col-span-8 xl:col-span-4">
                        <div className="flex gap-x-3">
                          <button
                            type="button"
                            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                            disabled={isLoading}
                            onClick={handlePersonalInfoChange}
                          >
                            {isLoading ? 'Saving...' : 'Save changes'}
                          </button>
                          <button
                            type="button"
                            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="py-6 sm:py-8 space-y-5 border-t border-gray-200 first:border-t-0 dark:border-neutral-700">
                    <div className="inline-flex items-center gap-x-2">
                      <h2 className="font-semibold text-gray-800 dark:text-neutral-200">
                        Password
                      </h2>

                      <div className="hs-tooltip inline-block">
                        <svg
                          className="hs-tooltip-toggle flex-shrink-0 ms-1 size-3 text-gray-500 dark:text-neutral-500"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                        <div
                          className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-[60] p-4 w-96 bg-white rounded-xl shadow-xl dark:bg-neutral-900 dark:text-neutral-400"
                          role="tooltip"
                        >
                          <p className="font-medium text-gray-800 dark:text-neutral-200">
                            Password requirements:
                          </p>
                          <p className="mt-1 text-sm font-normal text-gray-500 dark:text-neutral-400">
                            Ensure that these requirements are met:
                          </p>
                          <ul className="mt-1 ps-3.5 list-disc list-outside text-sm font-normal text-gray-500 dark:text-neutral-400">
                            <li>
                              Minimum 8 characters long - the more, the better
                            </li>
                            <li>At least one lowercase character</li>
                            <li>At least one uppercase character</li>
                            <li>
                              At least one number, symbol, or whitespace
                              character
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                      <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2">
                        <label
                          htmlFor="hs-pro-dappcp"
                          className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"
                        >
                          Current password
                        </label>
                      </div>

                      <div className="sm:col-span-8 xl:col-span-4">
                        <div className="relative">
                          <input
                            id="hs-pro-dappcp"
                            type="text"
                            className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                            placeholder="Enter current password"
                            disabled={isLoading}
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                          />
                          <button
                            type="button"
                            data-hs-toggle-password='{
                "target": "#hs-pro-dappcp"
              }'
                            className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 dark:text-neutral-600 dark:focus:text-blue-500"
                          >
                            <svg
                              className="flex-shrink-0 size-3.5"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                className="hs-password-active:hidden"
                                d="M9.88 9.88a3 3 0 1 0 4.24 4.24"
                              />
                              <path
                                className="hs-password-active:hidden"
                                d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"
                              />
                              <path
                                className="hs-password-active:hidden"
                                d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"
                              />
                              <line
                                className="hs-password-active:hidden"
                                x1="2"
                                x2="22"
                                y1="2"
                                y2="22"
                              />
                              <path
                                className="hidden hs-password-active:block"
                                d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"
                              />
                              <circle
                                className="hidden hs-password-active:block"
                                cx="12"
                                cy="12"
                                r="3"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                      <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2">
                        <label
                          htmlFor="hs-pro-dappnp"
                          className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"
                        >
                          New password
                        </label>
                      </div>

                      <div className="sm:col-span-8 xl:col-span-4">
                        <div
                          data-hs-toggle-password-group
                          className="space-y-2"
                        >
                          <div className="relative">
                            <input
                              id="hs-pro-dappnp"
                              type="text"
                              className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                              placeholder="Enter new password"
                              disabled={isLoading}
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <button
                              type="button"
                              data-hs-toggle-password='{
                  "target": ["#hs-pro-dappnp", "#hs-pro-dapprnp"]
                }'
                              className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 dark:text-neutral-600 dark:focus:text-blue-500"
                            >
                              <svg
                                className="flex-shrink-0 size-3.5"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path
                                  className="hs-password-active:hidden"
                                  d="M9.88 9.88a3 3 0 1 0 4.24 4.24"
                                />
                                <path
                                  className="hs-password-active:hidden"
                                  d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"
                                />
                                <path
                                  className="hs-password-active:hidden"
                                  d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"
                                />
                                <line
                                  className="hs-password-active:hidden"
                                  x1="2"
                                  x2="22"
                                  y1="2"
                                  y2="22"
                                />
                                <path
                                  className="hidden hs-password-active:block"
                                  d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"
                                />
                                <circle
                                  className="hidden hs-password-active:block"
                                  cx="12"
                                  cy="12"
                                  r="3"
                                />
                              </svg>
                            </button>
                          </div>
                          <div className="relative">
                            <input
                              id="hs-pro-dapprnp"
                              type="text"
                              className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                              placeholder="Repeat new password"
                              disabled={isLoading}
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                            <button
                              type="button"
                              data-hs-toggle-password='{
                  "target": ["#hs-pro-dappnp", "#hs-pro-dapprnp"]
                }'
                              className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 dark:text-neutral-600 dark:focus:text-blue-500"
                            >
                              <svg
                                className="flex-shrink-0 size-3.5"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path
                                  className="hs-password-active:hidden"
                                  d="M9.88 9.88a3 3 0 1 0 4.24 4.24"
                                />
                                <path
                                  className="hs-password-active:hidden"
                                  d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"
                                />
                                <path
                                  className="hs-password-active:hidden"
                                  d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"
                                />
                                <line
                                  className="hs-password-active:hidden"
                                  x1="2"
                                  x2="22"
                                  y1="2"
                                  y2="22"
                                />
                                <path
                                  className="hidden hs-password-active:block"
                                  d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"
                                />
                                <circle
                                  className="hidden hs-password-active:block"
                                  cx="12"
                                  cy="12"
                                  r="3"
                                />
                              </svg>
                            </button>
                          </div>

                          <div
                            data-hs-strong-password='{
                  "target": "#hs-pro-dappnp",
                  "stripClasses": "hs-strong-password:opacity-100 hs-strong-password-accepted:bg-teal-500 h-2 flex-auto rounded-full bg-blue-500 opacity-50 mx-1"
                }'
                            className="flex mt-2 -mx-1"
                          ></div>

                          <p className="text-sm text-gray-600 dark:text-neutral-400">
                            Level: <span></span>
                          </p>

                          <div className="flex items-center gap-x-3">
                            <button
                              type="button"
                              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                              onClick={handlePasswordChange}
                            >
                              Change
                            </button>
                            <div
                              className="text-sm text-blue-600 decoration-2 hover:underline font-medium focus:outline-none focus:underline dark:text-blue-400 dark:hover:text-blue-500 cursor-pointer"
                              onClick={handleForgetPassword}
                            >
                              I forgot my password
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="py-6 sm:py-8 space-y-5 border-t border-gray-200 first:border-t-0 dark:border-neutral-700">
                    <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                      <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2">
                        <label className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
                          Two-Step Verification
                        </label>
                      </div>

                      <div className="sm:col-span-8 xl:col-span-4">
                        <div
                          className="p-4 bg-blue-50 text-blue-600 rounded-lg bg-blue-500/10"
                          role="alert"
                        >
                          <div className="flex">
                            <svg
                              className="flex-shrink-0 size-5 mt-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z" />
                              <path d="m9 12 2 2 4-4" />
                            </svg>
                            <div className="ms-3 space-y-2">
                              <h3 className="text-sm text-blue-600 dark:text-blue-500">
                                Advanced security features are available on
                                Enterprise
                              </h3>
                              <button
                                type="button"
                                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                              >
                                <svg
                                  className="flex-shrink-0 size-3"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z" />
                                </svg>
                                Upgrade
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="py-6 sm:py-8 space-y-5 border-t border-gray-200 first:border-t-0 dark:border-neutral-700">
                    <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                      <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2">
                        <label className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
                          Connect accounts
                        </label>
                      </div>

                      <div className="sm:col-span-8 xl:col-span-4">
                        <div className="grid sm:flex gap-2">
                          <button
                            type="button"
                            className="py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                            onClick={handleGoogleConnect}
                          >
                            <svg
                              className="size-4"
                              width="33"
                              height="32"
                              viewBox="0 0 33 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_4132_5805)">
                                <path
                                  d="M32.2566 16.36C32.2566 15.04 32.1567 14.08 31.9171 13.08H16.9166V19.02H25.7251C25.5454 20.5 24.5866 22.72 22.4494 24.22L22.4294 24.42L27.1633 28.1L27.4828 28.14C30.5189 25.34 32.2566 21.22 32.2566 16.36Z"
                                  fill="#4285F4"
                                />
                                <path
                                  d="M16.9166 32C21.231 32 24.8463 30.58 27.5028 28.12L22.4694 24.2C21.1111 25.14 19.3135 25.8 16.9366 25.8C12.7021 25.8 9.12677 23 7.84844 19.16L7.66867 19.18L2.71513 23L2.65521 23.18C5.2718 28.4 10.6648 32 16.9166 32Z"
                                  fill="#34A853"
                                />
                                <path
                                  d="M7.82845 19.16C7.48889 18.16 7.28915 17.1 7.28915 16C7.28915 14.9 7.48889 13.84 7.80848 12.84V12.62L2.81499 8.73999L2.6552 8.81999C1.55663 10.98 0.937439 13.42 0.937439 16C0.937439 18.58 1.55663 21.02 2.63522 23.18L7.82845 19.16Z"
                                  fill="#FBBC05"
                                />
                                <path
                                  d="M16.9166 6.18C19.9127 6.18 21.9501 7.48 23.0886 8.56L27.6027 4.16C24.8263 1.58 21.231 0 16.9166 0C10.6648 0 5.27181 3.6 2.63525 8.82L7.80851 12.84C9.10681 8.98 12.6821 6.18 16.9166 6.18Z"
                                  fill="#EB4335"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_4132_5805">
                                  <rect
                                    width="32"
                                    height="32"
                                    fill="white"
                                    transform="translate(0.937439)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            {isGoogleConnected ? (
                              <>
                                Remove Google
                                <svg
                                  className="flex-shrink-0 w-3 h-3 text-gray-500 dark:text-neutral-500"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                </svg>
                              </>
                            ) : (
                              'Connect Google'
                            )}
                          </button>
                        </div>

                        <p className="mt-3 text-sm text-gray-500 dark:text-neutral-500">
                          Access with any email address, or by connecting a
                          Google account.
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <button
            type="button"
            title="Open Crop Modal"
            className="hidden"
            data-hs-overlay="#hs-pro-deum-crop"
          />

          <div
            id="hs-pro-deum-crop"
            className="hs-overlay hidden w-full h-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none [--close-when-click-inside:true] dark:hs-overlay-backdrop-open:bg-neutral-900/90"
          >
            <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0  ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center">
              <div className="max-h-full w-full flex flex-col bg-white rounded-xl pointer-events-auto shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-800 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]">
                <div className="p-4 flex justify-between items-center border-b border-neutral-200 dark:border-neutral-700">
                  <h3 className="font-semibold text-neutral-800 dark:text-neutral-200">
                    Crop Image
                  </h3>

                  <button
                    type="button"
                    className="w-8 h-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-neutral-100 text-neutral-800 hover:bg-neutral-200 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-neutral-200 dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                    data-hs-overlay="#hs-pro-deum-crop"
                  >
                    <span className="sr-only">Close modal</span>
                    <svg
                      className="flex-shrink-0 w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M18 6 6 18" />
                      <path d="m6 6 12 12" />
                    </svg>
                  </button>
                </div>

                <div className="overflow-y-auto p-4 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-neutral-100 [&::-webkit-scrollbar-thumb]:bg-neutral-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                  <div className="cropper-container relative w-full h-[400px]">
                    {/* {isCropLoading ? (
                  <div> Loading...</div>
                ) : (
                  imageSrc && ( */}
                    <Cropper
                      image={imageSrc}
                      crop={crop}
                      zoom={zoom}
                      aspect={4 / 4}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={onCropComplete}
                    />
                    {/* )
                )} */}
                  </div>
                  <div className="p-4 gap-x-2">
                    <button
                      type="button"
                      className={`text-center py-2 px-3 gap-x-2 text-sm font-semibold rounded-lg border border-transparent ${
                        isCropLoading ? 'bg-blue-800' : 'bg-blue-600'
                      } text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      onClick={submitCroppedImage}
                      disabled={isCropLoading}
                    >
                      {isCropLoading ? (
                        <div
                          className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-neutral-400 rounded-full"
                          role="status"
                          aria-label="loading"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        'Save Profile Photo'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
